/* ----- CSS ----- */

// Project CSS
require('@fortawesome/fontawesome-free/css/all.min.css');
require ('swiper/swiper-bundle.css');
require('../css/euro-est-services.css');
// require('../css/boutique.css');
import './../scripts/colorbox/colorbox.css';

/* ----- Javascript ----- */

const $ = require('jquery');
global.$ = global.jQuery = $;
require('jquery-ui');
require('jquery-sticky');
require ('swiper');

// Project JS
import './../scripts/colorbox/jquery.colorbox.js'
import './euro-est-services.js';
import './boutique.js';