$(document).ready(function () {

    /*
    STICKY MENU
    */
    $('header').sticky({
        topSpacing: 0,
        zIndex: 50
    });

    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function () {
        /*Ouvre le menu*/
        $('#nav').slideToggle();

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })
    /* Ouverture des sous-menu */
    if ($(window).width() < 1025) {
        $('#nav ul li a').on('click', function (e) {
            if ($(this).siblings('ul').length > 0) {
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
                $(this).unbind("click");
            }
        })
    }

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
        let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="' + title + '"]').removeClass('hidden');
    })

    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-' + id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function () {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function (e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

    /*
    SELECT LANGUE
     */
    $("#lang-select").on('click', function () {
        $(this).toggleClass('active').find('ul').slideToggle()
    });
    $("#lang-select").on('mouseleave', function () {
        $(this).removeClass('active').find('ul').slideUp()
    });

    if ($(window).width() < 1024) {
        let btn = $("#tous-vehicules").detach()
        btn.appendTo('#slider-vehicules')
    }

});

/*
SLIDERS
 */
import Swiper, {Autoplay, Navigation, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);

/* Slider hero */
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    autoplay: true,
    direction: 'horizontal',
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        1024: {
            direction: 'vertical',
        },
    }
})

swiperHero.on('slideChangeTransitionStart', function () {
    $('#myText').toggleClass('opacity-0');

    // il faut attendre que le chagement de slide active soit fait
    setTimeout(
        function () {
            const content = $('#slider-hero .swiper-slide-active .data-content').html();
            $('#myText').html(content);
            $('#myText').toggleClass('opacity-0');
        }, 250);

});

const swiperVehicule = new Swiper('#slider-vehicules', {
    loop: true,
    autoplay: true,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: {
        el: '.swiper-pagination-vehicule',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-vehicule-next',
        prevEl: '.swiper-vehicule-prev',
    },
    breakpoints: {
        780: {
            slidesPerView: 2,
            spaceBetween: 50,
            centeredSlides: false,
        },
        1280: {
            slidesPerView: 3,
            spaceBetween: 50,
            centeredSlides: true,
        },
    }
})

const swiperGalleryThumbs = new Swiper('#slider-gallery-thumbs', {
    loop: true,
    slidesPerView: 2,
    spaceBetween: 15,
    watchSlidesProgress: true,
    breakpoints: {
        1280: {
            slidesPerView: 3,
            centeredSlides: true,
        },
        1600: {
            slidesPerView: 4,
            centeredSlides: false
        }
    }
})

const swiperGalleryMain = new Swiper('#slider-gallery-main', {
    loop: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    thumbs: {
        swiper: swiperGalleryThumbs,
    },
    navigation: {
        nextEl: ".swiper-gallery_next",
        prevEl: ".swiper-gallery_prev",
    },
})

const swiperRecommendations = new Swiper('#slider-associes', {
    loop: true,
    slideToClickedSlide: true,
    navigation: {
        nextEl: ".swiper-associes_next",
        prevEl: ".swiper-associes_prev",
    },
    breakpoints: {
        780: {
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 25
        },
        1280: {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 50
        }
    }
})